<template>
  <div class="column chat__column">
    <ul
      id="chat-thread"
      class="chat-thread"
    />
    <div
      v-if="autoCompPlaceholder"
      class="clearit virtual-text"
    >
      <div class="hidden-text">
        {{ textInput }}
      </div>
      <input
        type="text"
        class="autocomplete"
        :placeholder="autoCompPlaceholder"
      >
    </div>
    <b-form
      id="chat-form"
      method="post"
      @keydown.enter.prevent="
        (messageToSend = textInput), (textInput = ''), sendMessage()
      "
    >
      <b-input-group class="chatInput">
        <b-form-input
          id="message"
          ref="message22"
          v-model="textInput"
          type="text"
          class="form-control"
          name="message"
          placeholder="Send a message"
          autocomplete="off"
          autofocus
          @blur="clearPlaceholder"
          @keyup="autoSuggest"
          @keydown.tab.prevent="fillAutocomplete"
        />
        <b-input-group-append>
          <b-button
            variant="outline-primary"
            class="emoji-picker-trigger"
            style="
                  margin-left: 0;
                  margin-right: 0;
                  padding-left: 10px;
                  padding-right: 10px;
                "
            @click.prevent="focusInput()"
          >
            <feather-icon
              icon="SmileIcon"
              class="mr-0 pt-0 pb-0"
            />
          </b-button>
          <b-button
            variant="outline-primary"
            class="emoji-picker-trigger"
            style="
                  margin-left: 0;
                  margin-right: 0;
                  padding-left: 10px;
                  padding-right: 10px;
                "
            @click.prevent="focusInput()"
          >
            <feather-icon
              icon="SettingsIcon"
              class="mr-0 pt-0 pb-0"
            />
          </b-button>
        </b-input-group-append>
        <button hidden>
          Send
        </button>
      </b-input-group>
    </b-form>
  </div>
</template>

<script>
import * as Ably from 'ably'
import {
  BButton,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BForm,
} from 'bootstrap-vue'
import { EmojiButton } from '@joeattardi/emoji-button'

export default {
  components: {
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BForm,
  },
  data() {
    return {
      options: {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'download',
          'mute',
          'volume',
          'settings',
          'fullscreen',
        ],
      },
      textInput: '',
      autoCompPlaceholder: '',
      suggestions: [],
      gameurl: 'https://www.igdb.com/games/',
      following: false,
      followers: null,
      twitterShare: 'http://twitter.com/share?url=https://clips.brimelive.com/',
      title: '',
      category: '',
      clipGenerating: false,
      clipDone: false,
      channelOwner: '',
      isChannelOwner: null,
      channelVods: [],
      userData: { username: '' },
      channelClips: [],
      clipVideoUrl: '',
      clips: '',
      clipChannel: '',
      isLive: false,
      clipChannelStuff: '',
      selectedClip: '',
      isSubscribed: true,
      clipID: null,
      live: false,
      chatUsers: null,
      testURL: 'https://players.akamai.com/players/hlsjs?streamUrl=',
      monetized: null,
      verified: false,
      brimeLLSuppported: true,
      resOptions: [],
      chat: [],
      message: '',
      messageToSend: '',
      channel: '',
      stream: {},
      chaturl: { url: 'https://beta.brimelive.com:8080/' },
      viewerIsLive: false,
    }
  },
  async mounted() {
    this.userData = await window.brime.getUser()
    // Emoji picker
    const picker = new EmojiButton(
      {
        position: {
          bottom: '0',
          right: '0',
        },
        categories: ['custom'],
        initialCategory: 'custom',
        showCategoryButtons: false,
        styleProperties: {
          '--font': 'Courier New',
          '--background-color': '#111727',
          '--text-color': 'white',
          '--secondary-text-color': 'white',
          '--category-button-color': 'linear-gradient(90deg,#833ab4,#fd1d1d)',
        },
        i18n: {
          search: 'Search emojis...',
          categories: {
            custom: 'Brime Global',
          },
          notFound: 'No emojis found',
        },
        custom: [
          {
            name: 'BrimeTime ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb7a0d2595193fc085ff9/1x',
          },
          {
            name: 'Doge ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb07bd2595193fc085ff6/1x',
          },
          {
            name: 'brimePop ',
            emoji:
              'https://content.brimecdn.com/brime/emote/60adf0acb02edcd85e096079/1x',
          },
          {
            name: 'Nashed ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607f931013b66ed6d03de8cc/1x',
          },
          {
            name: 'OBSSweat ',
            emoji:
              'https://content.brimecdn.com/brime/emote/6098e55d791971b70b1a3d05/1x',
          },
          {
            name: 'brimeDoge ',
            emoji:
              'https://content.brimecdn.com/brime/emote/60aeb56847e3a5efb5d862b9/1x',
          },
          {
            name: 'Pog ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607ba48ad2595193fc085ff4/1x',
          },
          {
            name: 'KEKW ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607ba5dcd2595193fc085ff5/1x',
          },
          {
            name: 'FeelsBrimeProMan ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607fa67d13b66ed6d03de93e/1x',
          },
          {
            name: 'PauseChamp ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb721d2595193fc085ff8/1x',
          },
          {
            name: 'Jebaited ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb52fd2595193fc085ff7/1x',
          },
          {
            name: 'monkaW ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb833d2595193fc085ffa/1x',
          },
          {
            name: 'YEP ',
            emoji:
              'https://content.brimecdn.com/brime/emote/608c9405e2e12599035c6f61/1x',
          },
        ],
      },
      {
        autoHide: true,
      },
    )

    picker.on('emoji', selection => {
      if (selection.url) {
        this.old = this.message
        this.message = this.old + selection.name
      } else {
        this.old = this.message
        this.message = this.old + selection.emoji
      }
    })

    document
      .querySelector('.emoji-picker-trigger')
      .addEventListener('click', e => picker.togglePicker(e.targetElement))

    const parseURL = window.location.pathname.substring(1)
    const chan = parseURL.substring(0, parseURL.lastIndexOf('/'))
    const channelData = await window.brime.getChannel(chan)
    // eslint-disable-next-line no-underscore-dangle
    this.channelId = channelData._id
    // Moderator detection
    if (this.userData) {
      // eslint-disable-next-line no-underscore-dangle
      this.isModerator = channelData.moderators.includes(this.userData._id)
        // eslint-disable-next-line no-underscore-dangle
        || channelData.owners.includes(this.userData._id)
    }
    /* eslint-disable no-underscore-dangle */
    const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // eslint-disable-next-line
    const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line
    const v = c == "x" ? r : (r & 0x3) | 0x8;

      return v.toString(16)
    })
    const getUserData = () => {
      function parseJwt(token) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
        )
        return JSON.parse(jsonPayload)
      }
      if (localStorage.getItem('accessToken')) {
        const userData = parseJwt(localStorage.getItem('accessToken'))
        userData.role = 'user'
        userData.avatar = 'https://i.ibb.co/fMF9pQJ/Doge-Tri-Poloski.png'
        userData.ability = [{ action: 'manage', subject: 'all' }]
        return userData
      }
      return {
        userData: {},
        avatarText: {},
      }
    }
    const getUUID = () => {
      let uuid = localStorage.getItem('brime_uuid')

      if (!uuid) {
        uuid = uuidv4()
        localStorage.setItem('brime_uuid', uuid)
      }

      return uuid
    }
    const userData = getUserData()
    const clientId = localStorage.getItem('accessToken')
      ? userData.username
      : getUUID()
    this.realtime = new Ably.Realtime({
      key: 'TTKZpg.6NfkQA:gAXhL8RYoA8iQ2o7',
      clientId,
    })
    this.channelChat = this.realtime.channels.get(
      `[?rewind=20]${this.channelId}/chat`,
    )
    this.realtime.connection.on('connected', () => {})
/* eslint-disable */ 
    this.channelChat.presence.enter(null, () => {
    })
   
    this.updateChatters()
    // Chat
    function escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    }
    function replaceURLs(message) {
      if (message) {
        const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi

        return message.replace(urlRegex, url => {
          let hyperlink = url
          if (!hyperlink.match('^https?://')) {
            hyperlink = `http://${hyperlink}`
          }
          return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`
        })
      }

      return null
    }
    this.channelChat.subscribe(message => {
      const obj = JSON.parse(message.data)
      const type = message.name

      const isRewind = message.timestamp < (Date.now() - 10000) // If it is older than 10s.

      switch (type) {
        case 'raid_target': {
          if (!isRewind) {
            const targetChanel = obj.targetChannel

            // Send them on their way
            this.$router.push({ path: `/${targetChanel}` })
          }
          break
        }

        case 'delete': {
          const element = document.getElementById(obj.messageID)

          // eslint-disable-next-line
          element?.remove();
          break
        }

        case 'chat': {
          let badgeHtml = ''
          let messageContents = escapeHtml(obj.message)

          messageContents = messageContents.replace('https://', '')
          messageContents = messageContents.replace('http://', '')
          messageContents = replaceURLs(messageContents)

          Object.entries(obj.emotes ?? {}).forEach(([key, value]) => {
            const html = `<img alt="${key}" title="${key}" src="https://content.brimecdn.com/brime/emote/${value._id}/1x" />`
            messageContents = messageContents.split(key).join(html)
          })

          obj.sender.badges.forEach(badge => {
            badgeHtml += `<img class="chatBadge" src="${badge}" style="height: 1.20em; vertical-align: middle;">`
          })

          let msgElem = ''

          if (this.isModerator) {
            msgElem += `<a title="Delete Message" onclick="brime.deleteMessage('${this.channelId}', '${obj._id}')"><svg xmlns="http://www.w3.org/2000/svg" style="fill: rgba(255, 255, 255, 0.2); margin-left: 0px;" width="16" height="16" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg></a>&nbsp;`
          }

          const isImportantMessage = ['BRIMEBOT'].includes(obj.sender._id)
          let displayContents = obj.richContents ?? messageContents

          // Replace newlines and tabs
          displayContents = displayContents.replace(/\n/g, '<br>')
          displayContents = displayContents.replace(/\t/g, '    ')

          msgElem += `
            <span style="margin-left: -3px;">${['BRIMEBOT'].includes(obj.sender._id) ? '&nbsp; ' : badgeHtml}</span>
            <span style="color: ${escapeHtml(obj.sender.color)}"><b><a style="font-size: 13px; color: inherit;" href="/${escapeHtml(obj.sender.displayname)}" target="_blank" style="color: ${escapeHtml(obj.sender.color)}">${['BRIMEBOT'].includes(obj.sender._id) ? '' : escapeHtml(obj.sender.displayname)}</a></b></span><span style="font-size: 13px;"> ${displayContents}</span>
          `

          // Add message to chat thread and scroll to bottom
          const ul = document.getElementById('chat-thread')
          const li = document.createElement('li')
          li.id = obj._id
          li.innerHTML = msgElem
          li.setAttribute('data-v-4cb7cfca', '')
          li.setAttribute('class', 'chatLine')
          ul.appendChild(li)
          ul.scrollTop = ul.scrollHeight

          if (isImportantMessage) {
            li.setAttribute('style', 'background-color: rgba(255, 255, 255, 0.1); padding-top: 5px !important; padding-bottom: 5px !important; border-left: 4px solid #7367f0 ;')
          }
          const isUser = [`${userData.username}`].includes(obj.message.toLowerCase())
          const isUserAt = [`@${userData.username}`].includes(obj.message.toLowerCase())
          if (isUser || isUserAt) {
            li.setAttribute('style', 'background-color: rgba(255, 255, 255, 0.1); padding-top: 5px !important; padding-bottom: 5px !important; border-left: 4px solid #7367f0 ;')
          }
          // chatThread.scrollTop = chatThread.scrollHeight;
          break
        }

        default:
          break
      }
    })
  },
  /* eslint-disable */ 
  methods: {
    updateChatters() {
    let vm = this;
    this.channelChat.presence.get((err, members) => {
      var i
      const users = []
      let vm = this
      for (const index in members) {
        vm.suggestions.push(`${members[index].clientId}`)
      }
    })},
    clearPlaceholder() {
      this.autoCompPlaceholder = ''
    },
    fillAutocomplete() {
      this.textInput += this.autoCompPlaceholder
      this.autoCompPlaceholder = ''
    },
    autoSuggest() {
      const input = this.textInput
      this.autoCompPlaceholder = ''
      const self = this

      if (input.includes('@')) {
        const index = input.indexOf('@')
        if (input[index + 1]) {
          const inputToAutocomplete = input.substring(index + 1)
          let flag = false
          this.suggestions.forEach((suggestion, index) => {
            if (flag) return
            if (suggestion.indexOf(inputToAutocomplete) == 0) {
              self.autoCompPlaceholder += suggestion.substring(inputToAutocomplete.length)
              flag = true
            }
          })
        }
      }
    },
    async sendMessage() {
      const response = await window.brime.sendChatMessage(
        this.messageToSend,
        this.channelId,
      )

      if (response.message) {
        // Add message to chat thread and scroll to bottom
        const ul = document.getElementById('chat-thread')
        const li = document.createElement('li')

        li.innerText = response.message
        li.setAttribute('data-v-4cb7cfca', '')
        li.setAttribute('class', 'chatLine')
        li.style.color = '#b4b7bd'

        ul.appendChild(li)
        ul.scrollTop = ul.scrollHeight
      }

      this.message = ''
    },
  },
}
</script>

<style>
.dark-layout .nav-tabs .nav-item .nav-link.active {
  background-color: unset !important;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
}
.chatBadge:not(:first-child) {
  padding-left: 2px;
}
.loadingClip {
  width: 100%;
}
.clipVideo {
  width: 100%;
}
.clipButton {
  margin-top: 5px;
  margin-right: 30px;
}
.subButton {
  margin-top: 5px;
  margin-right: 0px;
}
.followers {
  padding-left: 10px;
  font-size: 14px;
}
.streamTitle {
  padding-top: 5px;
  font-size: 14px;
}
.streamCategory {
  margin-top: -20px;
  margin-left: 55px;
  font-size: 14px;
}

.chat-thread {
  padding-left: 5px;
  padding-top: 7px;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90% !important;
}

.chat-thread li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 1px 5px 1px 10px;
  margin: 0 0 10px 0;
  font: 16px/20px;
  width: 100%;
}

/* Chat - Speech Bubble Arrow */
.chat-thread li:before {
  position: absolute;
  top: 5px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
}

.chat-thread li {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: left;
  margin-left: 0px;
  color: #fff;
  word-wrap: break-word;
}

.chat-thread li:after {
  border-right: 15px solid transparent;
  right: -15px;
}

.chat-window {
  position: fixed;
  bottom: 12px;
  right: 0;
  width: 100%;
}
.chatInput {
  margin-left: 2.5%;
  margin-right: 2.5%;
  width: 96%;
  bottom: 0;
  box-shadow: none !important;
}

.chat-window-message {
  width: 100%;
  height: 48px;
  font: 32px/48px "Noto Sans", sans-serif;
  background: none;
  color: #ffff;
  border: 0;
  border-bottom: 1px solid rgba(25, 147, 147, 0.2);
  outline: none;
  bottom: 0;
  display: fixed;
}

/* Small screens */
@media all and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
 .chat-thread {
    width: 100%;
    height: 85vh;
  }
  .chat-window {
    left: 5%;
    width: 90%;
  }
  #stream-video {
    min-height: unset !important;
    height: unset !important;
  }
  .column1 {
    width: 80%;
    margin-left: -15px;
    height: unset !important;
    margin-top: -10px;
  }
  #channelName {
    font-size: 10px !important;
  }
  .tabSection {
    display: none;
  }
  .actionButtons {
    right: -15px !important;
    position: fixed;
  }
  #channel {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-left: 2px !important;
  }
  .viewcount {
    font-size: 12px !important;
  }
  .streamCategory {
    margin-top: 2px !important;
    margin-left: 0 !important;
  }
}
@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
</style>
<style scoped>
.card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 0px 20px rgba(115, 103, 240, 1);
}

.card img {
  transition: all 0.2s;
}
.card {
  box-shadow: none;
  padding: 0.5rem;
}
.vod__category {
  color: #7367f0 !important;
}
.card-body {
  padding: unset !important;
}
.card {
  background-color: unset !important;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-4px);
}

.card:hover .vod__image {
  opacity: 1;
}

.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vod__image {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s;
}

.vod__duration {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.vod__category,
.vod__date {
  font-size: 0.9em;
  color: #fff;
}

.tabSection {
  margin-top: 8vh;
  margin-left: 1vw;
  margin-right: 1vw;
}
.vodSection {
  margin-top: 5vh;
}
#stream-video {
  /* override other styles to make responsive */
  width: 100% !important;
}
.btn {
  margin-left: 10px !important;
}
.disabled {
  margin-left: 0px !important;
}
.actionButtons {
  margin-top: -50px;
  margin-right: 20px;
}
.creatorMenu {
  margin-top: 10px;
}
#doge-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9001;
}
.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
#channel {
  margin-left: 15px;
  margin-top: 15px;
  top: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
}
#channel img {
  border-radius: 50%;
  vertical-align: middle;
}
button {
  margin-left: 20px;
}
.column1 {
  width: 80%;
  margin-left: -15px;
  height: 80vh;
  margin-top: -5px;
}
.column {
  width: 100%;
  flex: 1;
  background: #111727;
  margin-right: -12px;
  padding-bottom: 2vh;
  height: 100%;
}
.row {
  margin-top: -25px !important;
}
.channel__row {
  flex-wrap: nowrap;
}

#player {
  flex-shrink: 0;
}
.content-body {
  height: 100% !important;
}

  .chat__column {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    top: var(--headerHeight);
    height: calc(100%);
    padding-bottom: 1rem;
  }

  .chat-thread {
    flex: 1;
  }

  #chat-form {
    flex-shrink: 0;
  }

</style>
<style>
.custom-input {
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px 20px;
  margin: 8px;
  color: black !important;
}
.virtual-text{
  width: auto;
  position: absolute;
  bottom: 2px;
  left: 58px;
  overflow: hidden;
  color: black !important;
  z-index: 20;
  font-size: 1rem;
}
.hidden-text {
  width: auto;
  visibility: hidden;
  /* border: 1px solid green; */
  float: left;
  font-size: 15px;
  color: black !important;
}
.autocomplete {
  color: black !important;
  z-index: 5;
  background-color: transparent;
  width: auto;
  border-bottom: none;
  float: left;
  outline: none;
  border: none;
  /* border: 1px solid red; */
  line-height: 1.5;
  padding: 12px 0px;
  margin: 8px 2px;
}
</style>